import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AppService } from "./shared/app.service";

@Component({
  template: '<router-outlet></router-outlet>'
})
export class ApplicationsComponent implements OnInit {
    
  constructor(private router: Router, private AppService:AppService) {

  }

  ngOnInit() { 
  }

}
