import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APP_CONFIG } from './../../app-config';
import { AppService } from "./../shared/app.service";
import * as $ from 'jquery';

@Component({
    selector: 'app-application-details',
    templateUrl: './application-details.component.html'
})
export class ApplicationDetailsComponent implements OnInit {
    appDetailsArray: any = [];
    applicationId = '';
    Platform: any;
    launchpadcdn = APP_CONFIG.launchpadcdn;
    notificationListArr = "";
    notificationLength = [];
    imgUrl="";
    displayInstructions: boolean = true;

    constructor(private router: Router, private AppService: AppService, private route: ActivatedRoute, private elementRef: ElementRef) { }

    ngOnInit() {
        this.getAppDetails();
    }

    ngAfterViewInit() {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = "assets/plugins/bootstrap-select/js/select-filter.js";
        this.elementRef.nativeElement.appendChild(s);
    }

    private getAppDetails() {
        let applicationId = this.route.snapshot.params["id"];
        var fullRoute = window.location.pathname;
        var code = fullRoute.split("/");
        this.AppService.getApplicationDetails(code[1], applicationId).subscribe(response => {
            if (response.status == 1) {
                this.appDetailsArray = response.response;
                this.imgUrl=response.response.defaulturl;
                this.notificationLength = response.response.notificationList.length;
                this.Platform = JSON.parse(response.response.platform);
            } else {
                this.router.navigateByUrl('/404');
            }
        });
    }

    public showNotificationList(rowObj) {
        this.notificationListArr = rowObj;
        $('#nDetail').click();
    }

    public openApp(appId, appType, urls, websiteUrl) {
        if(websiteUrl && websiteUrl!=''){
            window.open(websiteUrl, '_blank');
            return;
        }
        if(urls == '') {
            return;
        }
        if(appType == 7 || appType == 8 || appType == 9 || appType == 15 || appType == 16){
            /******START Check Tenant login or not******/
            this.AppService.checkIsloggedin().subscribe(response => {
                if (response.status == 0) {
                     window.open(APP_CONFIG.urls + '/' + window.location.pathname.split("/")[1], '_blank');
                     return;
                }            
                this.AppService.childOpenApp(appId, appType, urls);
            });
            /******END Check Tenant login or not******/
        } else {
            this.AppService.childOpenApp(appId, appType, urls);
        }
    }

    formatImageUrl(url) {
        var newUrl = url.split('.');
        newUrl[2] = newUrl[2] + '@2x';
        newUrl = newUrl.join('.').replace('default', 'hires');
        return newUrl;
    }
}
