import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchPipe'
})
export class SearchPipePipe implements PipeTransform {
    isArr(Arr: any) {
        if (Object.prototype.toString.call(Arr) == "[object Array]") {
            return true;
        }
        return false;
    }
    arrayContainsArray(superset, subset) {
        if (0 === subset.length) {
            return false;
        }
        return subset.every(function (value) {
            return (superset.indexOf(value) >= 0);
        });
    }
    public transform(items, nameSearch, typeSearch, deviceSearch, schoolSearch, gradeSearch, subjectSearch, statusSearch) {
        if (items && items.length) {
            return items.filter(item => {
                if (!item.ApplicationName) {
                    item.ApplicationName = "";
                }
                if (nameSearch && item.ApplicationName && item.ApplicationName.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1) {
                    return false;
                }
                if (item.title) {
                    if (item.apps && item.apps.length > 0) {
                        var flag = false;
                        if (!nameSearch && typeSearch.length == 0 && schoolSearch.length == 0 && gradeSearch.length == 0 && subjectSearch.length == 0 && statusSearch == -1 && deviceSearch == -1) {
                            return true
                        }
                        item.type = [];
                        item.subject = [];
                        item.grades = [];
                        item.school = [];
                        item.status = [];
                        item.platform = [];
                        item.AppNames = [];
                        item.AppNames.push(item.title);
                        item.apps.map((row) => {
                            if (row.hasOwnProperty('ApplicationName')) {
                                item.AppNames.push(row['ApplicationName']);

                                if (this.isArr(row['type'])) {
                                    row['type'].map((i) => item.type.push(i));
                                }
                                if (this.isArr(row['subject'])) {
                                    row['subject'].map((i) => item.subject.push(i));
                                }
                                if (this.isArr(row['grades'])) {
                                    row['grades'].map((i) => item.grades.push(i));
                                }
                                if (this.isArr(row['school'])) {
                                    row['school'].map((i) => item.school.push(i));
                                }

                                if (row['status']) {
                                    item.status.push(row['status']);
                                }
                                if (row['platform']) {
                                    try { row.platform = JSON.parse(row.platform); } catch (e) { }
                                    if (Object.prototype.toString.call(row.platform) == "[object Object]") {
                                        if (parseInt(row.platform.EnableAndroid) == 1 && !item.platform.includes('EnableAndroid')) {
                                            item.platform.push("EnableAndroid");
                                        }
                                        if (parseInt(row.platform.EnableIOS) == 1 && !item.platform.includes('EnableIOS')) {
                                            item.platform.push("EnableIOS");
                                        }
                                        if (parseInt(row.platform.EnableWeb) == 1 && !item.platform.includes('EnableWeb')) {
                                            item.platform.push("EnableWeb");
                                        }
                                        if (parseInt(row.platform.EnableApple) == 1 && !item.platform.includes('EnableApple')) {
                                            item.platform.push("EnableApple");
                                        }
                                        if (parseInt(row.platform.EnableWindows) == 1 && !item.platform.includes('EnableWindows')) {
                                            item.platform.push("EnableWindows");
                                        }
                                    }
                                }
                            }
                        });
                    }
                    if (nameSearch && item.ApplicationName && !item.AppNames.join().includes(nameSearch)) {
                        return false;
                    }

                    if (deviceSearch && deviceSearch != -1) {
                        if (deviceSearch == 1 && item.platform && !item.platform.includes('EnableAndroid')) {
                            return false;
                        } else if (deviceSearch == 2 && !item.platform.includes('EnableIOS')) {
                            return false;
                        } else if (deviceSearch == 3 && !item.platform.includes('EnableWeb')) {
                            return false;
                        } else if (deviceSearch == 4 && !item.platform.includes('EnableApple')) {
                            return false;
                        } else if (deviceSearch == 5 && !item.platform.includes('EnableWindows')) {
                            return false;
                        }
                    }
                    if (this.isArr(item.school) && this.isArr(schoolSearch)) {
                        if (schoolSearch.length > 0 && !this.arrayContainsArray(item.school,schoolSearch)) {
                            return false;
                        }
                    }
                    if (this.isArr(item.type) && this.isArr(typeSearch)) {
                        if (typeSearch.length > 0 && !this.arrayContainsArray(item.type,typeSearch)) {
                            return false;
                        }
                    }

                    if (this.isArr(item.subject) && this.isArr(subjectSearch)) {
                        if (subjectSearch.length > 0 && !this.arrayContainsArray(item.subject,subjectSearch)) {
                            return false;
                        }
                    }

                    if (this.isArr(item.grades) && this.isArr(gradeSearch)) {
                        if (gradeSearch.length > 0 && !this.arrayContainsArray(item.grades,gradeSearch)) {
                            return false;
                        }
                    }
                    if (statusSearch && item.status && !item.status.includes(statusSearch) && statusSearch != -1) {
                        return false;
                    }
                    return true;
                } else {
                    if (deviceSearch && deviceSearch != -1 && item.platform) {
                        if (deviceSearch == 1 && parseInt(item.platform.EnableAndroid) == 0) {
                            return false;
                        } else if (deviceSearch == 2 && parseInt(item.platform.EnableIOS) == 0) {
                            return false;
                        } else if (deviceSearch == 3 && parseInt(item.platform.EnableWeb) == 0) {
                            return false;
                        } else if (deviceSearch == 4 && parseInt(item.platform.EnableApple) == 0) {
                            return false;
                        } else if (deviceSearch == 5 && parseInt(item.platform.EnableWindows) == 0) {
                            return false;
                        }
                    }

                    if (this.isArr(item.school) && this.isArr(schoolSearch)) {
                        if (schoolSearch.length > 0 && !this.arrayContainsArray(item.school,schoolSearch)) {
                            return false;
                        }
                    }
                    if (this.isArr(item.type) && this.isArr(typeSearch)) {
                        if (typeSearch.length > 0 && !this.arrayContainsArray(item.type,typeSearch)) {
                            return false;
                        }
                    }

                    if (this.isArr(item.subject) && this.isArr(subjectSearch)) {
                        if (subjectSearch.length > 0 && !this.arrayContainsArray(item.subject,subjectSearch)) {
                            return false;
                        }
                    }

                    if (this.isArr(item.grades) && this.isArr(gradeSearch)) {
                        if (gradeSearch.length > 0 && !this.arrayContainsArray(item.grades,gradeSearch)) {
                            return false;
                        }
                    }

                    if (statusSearch && item.status != statusSearch && statusSearch != -1) {
                        return false;
                    }
                    return true;
                }
            });
        }
        else {
            return items;
        }
    }

}