import {Routes} from '@angular/router';
import {ApplicationsComponent} from "./applications/applications.component";
import { ApplicationListComponent } from './applications/application-list/application-list.component';
import { ApplicationDetailsComponent } from './applications/application-details/application-details.component';
import { CollectionComponent } from './collection/collection.component';

import { MissingComponent } from './missing/missing.component';
import { NotfoundComponent } from './notfound/notfound.component';


export const appRoutes: Routes = [
  {path: '',  component: MissingComponent},
  {path: '404',  component: NotfoundComponent},
  {
    path: ':code', component: ApplicationsComponent,children : [
            {path: '', component: ApplicationListComponent},
            {path: 'app/:id', component: ApplicationDetailsComponent},
            {path: 'collection/:id', component: CollectionComponent},
            {path: '**', component: NotfoundComponent},
    ]
  }
];

