import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from './../../app-config';
import { Observable } from 'rxjs';


@Injectable()

export class AppService {
    constructor(private http: HttpClient) { }

    childOpenApp(appId, appType, urls) {
        switch (appType) {
            case 1:
                var url = (new RegExp(/^(http|https):\/\//).test(urls) ? urls : ('http://' + urls));
                window.open(url, '_blank');
                break;
            /*            case 7:
                            this.openRDPClient(appid);
                            break;
                        case 8:
                            this.openRDPClient(appid);
                            break;*/
            case 9:
                window.open(APP_CONFIG.urls + '/clsso/' + encodeURIComponent(appId), '_blank');
                break;
            case 14:
                window.open(urls, '_blank');
                break;
            case 15:
                window.open(APP_CONFIG.urls + '/browsersso/' + encodeURIComponent(appId), '_blank');
                break;
            case 16:
                window.open(APP_CONFIG.urls + '/ltisso/' + appId, '_blank');
                break;
            case 17:
                window.open(APP_CONFIG.urls + '/focussso/' + appId, '_blank');
                break;
            case 18:
                window.open(APP_CONFIG.urls + '/pearson/mathxl/' + appId, '_blank');
                break;
            case 19:
                window.open(APP_CONFIG.urls + '/pearson/mymathlab/' + appId, '_blank');
                break;
            case 20:
                window.open(APP_CONFIG.urls + '/custom/certification/' + appId, '_blank');
                break;
            case 21:
                window.open(APP_CONFIG.urls + '/oneroster/' + appId, '_blank');
                break;
            case 22:
                window.open(APP_CONFIG.urls + '/phonebook/' + appId, '_blank');
                break;
            case 23:
                window.open(APP_CONFIG.urls + '/onerosterlti/' + appId, '_blank');
                break;
            case 24:
                window.open(APP_CONFIG.urls + '/assignapplication/' + appId, '_blank');
                break;
            case 25:
                var url = (new RegExp(/^(http|https):\/\//).test(urls) ? urls : ('http://' + urls));
                window.open(url, '_blank');
                break;
            case 26:
                var url = (new RegExp(/^(http|https):\/\//).test(urls) ? urls : ('http://' + urls));
                window.open(url, '_blank');
                break;
            /*            case 3:
                        case 27:
                            if(parseInt(IdConfig.modules.myfiles.newOpenLocallyInstalled) == 1){
                                var token = urls;
                                ithis.launchLocalApp(token);                                        
                            } else {
                                utils.showLoading();
                                utils.checklocalAgentInstalled(0, function(err, local_agent_status) {
                                  utils.hideLoading();
                                  if (parseInt(local_agent_status) == 0) {
                                    utils.showMessage(i18n.t("myapps.local_agent_outdated_and_revoked"), 'error');
                                  } else {
                                    var localAppBroserInt = setInterval(function() {
                                        if(parseInt(IdConfig.modules.myfiles.newOpenLocallyInstalled) == 1){
                                            clearInterval(localAppBroserInt);
                                            setTimeout(function() {
                                                var token = urls;
                                                ithis.launchLocalApp(token);
                                            }, 1000);                                
                                        }
                                    }, 10);
                                  }
                                }, function(err) { //failure
                                    utils.hideLoading();
                                    utils.showMessage(i18n.t("myapps.local_agent_not_installed"), 'error'); 
                                });
                            }
                            break;*/
            case 28:
                window.open(APP_CONFIG.urls + '/custom/genericoneroster/ltilaunch/' + appId, '_blank');
                break;
            case 29:
                window.open(APP_CONFIG.urls + '/custom/pearsonapapp/' + appId, '_blank');
                break;
            case 30:
                var url = (new RegExp(/^(http|https):\/\//).test(urls) ? urls : ('http://' + urls));
                window.open(url, '_blank');
                break;
            case 31:
                var url = (new RegExp(/^(http|https):\/\//).test(urls) ? urls : ('http://' + urls));
                window.open(url, '_blank');
                break;
            case 32:
                var url = (new RegExp(/^(http|https):\/\//).test(urls) ? urls : ('http://' + urls));
                window.open(url, '_blank');
                break;
            case 33:
                window.open(APP_CONFIG.urls + '/custom/pearsonapapp/' + appId, '_blank');
                break;
            case 34:
                window.open(APP_CONFIG.urls + '/custom/naviancestudentsso/' + appId, '_blank');
                break;
            case 35:
                window.open(APP_CONFIG.urls + '/oneroster/manage/class/' + appId, '_blank');
                break;
        }
    }

    getSettingByCode(code: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/settings${code}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getAppDetailsByCode(code: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/apps${code}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getAppSearchDetailsByName(code: any, sSearch: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/collection${code}?sSearch=${sSearch}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getAppFiltersList(code: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/filters${code}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getApplicationDetails(code: any, id: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/app/${code}/${id}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getCollectionsDetails(code: any, collectionid: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/collection/${code}/${collectionid}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getCollectionsApplicationDetails(code: any, id: any, lpapplicationid: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/app/${code}/${id}/${lpapplicationid}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getNotificationDetails(code: any, id: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/appnotification${code}/${id}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    getCollectionNotificationDetails(code: any, id: any): Observable<any> {
        let requestUrl = `${APP_CONFIG.nodeapiUrl}/collectionnotification${code}/${id}`;
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let options = {
            headers: requestHeader
        }
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }

    checkIsloggedin(): Observable<any> {
        let requestUrl = APP_CONFIG.urls + '/isloggedin?t=' + (new Date()).getTime();
        let requestHeader = new HttpHeaders({'Content-Type':'application/json'});
        let requestParams = new HttpParams();
        let options = { headers: requestHeader, params: requestParams, withCredentials: true };
        return this.http.get(requestUrl, options)
            // .map((res: Response) => {
            //     let response = res.json();
            //     return response;
            // });
    }
}