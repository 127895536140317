import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationsComponent } from '../applications.component';
import { AppComponent } from './../../app.component';
import { APP_CONFIG } from './../../app-config';
import { AppService } from "./../shared/app.service";
import * as $ from 'jquery';
import * as _ from 'lodash';
import {MultiSelect} from 'primeng/primeng';
import { Observable, Subject, from } from 'rxjs';
import { concatMap, map, scan, share} from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html'
})

export class ApplicationListComponent implements OnInit {
  collectionappArray = [];
  featuredApplicationArray = [];
  applicationAndFeaturedArray:Observable<Array<any>>;
  featuredAppIndex = 0;
  fadeOutFeatured = false;
  notificationArr = [];
  notificationDetail: any = "";
  collectionNotificationArr = [];
  nLoader = false;
  IsHidden = true;
  launchpadcdn = APP_CONFIG.launchpadcdn;
  searchText: string = '';
  search: string = '';
  title = '';
  filterArray: any = [];
  selectIndex: number;
  disableClass: string;
  totalLength: number;
  isPrevNext = false;
  districtDetails: any;
  nameSearch: any;
  typeSearch: any = [];
  deviceSearch: any = -1;
  schoolSearch: any = [];
  gradeSearch: any = [];
  statusSearch: any = -1;
  subjectSearch: any = [];

  typeSearchName: string = "";
  deviceSearchName: string = "All Platforms";
  schoolSearchName: string = "";
  gradeSearchName: any;
  statusSearchName: string = "All Statuses";
  subjectSearchName: string = "";
  time:any;

  constructor(private router: Router, private AppService: AppService, private elementRef: ElementRef, private renderer: Renderer2, private appComponent: AppComponent) {
    $(document).ready(() => {

    });
  }

  ngOnInit() {
    this.getFiltersList();
    this.getApplicationCollectioonsList();
    this.filterExpand();
    this.districtDetails = this.appComponent.districtDetails;
    MultiSelect.prototype.close = function (event) {
      if($(this.el.nativeElement).hasClass('grades')){
         $("#clearGrade").click();
      }else if($(this.el.nativeElement).hasClass('types')){
        $("#clearType").click();
      }else if($(this.el.nativeElement).hasClass('schools')){
        $("#clearSchool").click();
      }else if($(this.el.nativeElement).hasClass('subjects')){
        $("#clearSubject").click();
      }else if($(this.el.nativeElement).hasClass('devices')){
        $("#clearDevice").click();
      }else if($(this.el.nativeElement).hasClass('statuses')){
        $("#clearStatus").click();
      }
      this.hide();
      event.preventDefault();
      event.stopPropagation();
    };
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "assets/plugins/bootstrap-select/js/select-filter.js";
    this.elementRef.nativeElement.appendChild(s);
  }

  private getApplicationCollectioonsList() {
    const timeOf = (interval: number) => <T>(val: T) =>
      timer(interval).pipe(map(x => val));

    this.AppService.getAppDetailsByCode(this.router.url).subscribe(response => {
      //this.appListArray = response.response.applicationArray;
      this.collectionappArray = response.response.collectionappArray;
      this.featuredApplicationArray = _.filter(response.response.applicationAndFeaturedArray, function (row) { if (row.featured == 1) return row; });
      this.applicationAndFeaturedArray = from(response.response.applicationAndFeaturedArray)
      .pipe(
        concatMap(timeOf(10)),
        map(x => [x]),
        scan((acc, val) => [...acc, ...val]),
        share()
      );
    });
  }

  private getFiltersList() {
    this.AppService.getAppFiltersList(this.router.url).subscribe(response => {
      if (response.status == 1) {
        this.filterArray = response.response;
        this.filterArray.types = _.orderBy(this.filterArray.types, ['id'], ['asc']);
        this.filterArray.platforms = _.orderBy(this.filterArray.platforms, ['id'], ['asc']);
        this.filterArray.platforms.unshift({ id: -1, name: "All Platforms" });
        this.filterArray.schools = _.orderBy(this.filterArray.schools, ['name'], ['asc']);
        this.filterArray.statuses = _.orderBy(this.filterArray.statuses, ['id'], ['asc']);
        this.filterArray.statuses.unshift({ id: -1, name: "All Statuses" });
      }
    });
  }

  public myAppDetail(id, customurlcode) {
    this.router.navigate(['/' + customurlcode + '/app', id]);
  }

  public myCollectionDetail(id, customurlcode) {
    this.router.navigate(['/' + customurlcode + '/collection', id]);
  }

  public showNotification(notificationObj) {
    this.nLoader = false;
    this.isPrevNext = false;
    this.notificationArr = [];
    this.notificationArr = notificationObj;
    this.showNotificationDetail();
    // this.AppService.getNotificationDetails(this.router.url, app_id).subscribe(response => {
    //     this.nLoader = true;
    //     if (response.status == 1) {
    //         this.isPrevNext = true;
    //         this.notificationArr = response.response;
    //         if (this.notificationArr.length > 0) {
    //             this.isPrevNext = false;

    //         }
    //     }
    // });
  }

  public showNotificationDetail() {
    $('#nDetail').click();
  }

  public showCollectionNotification(notificationObj) {
    this.nLoader = false;
    this.isPrevNext = false;
    this.notificationArr = [];
    this.notificationArr = notificationObj;
    this.showNotificationDetail();
    /*this.AppService.getCollectionNotificationDetails(this.router.url, app_id).subscribe(response => {
        this.nLoader = true;
        if (response.status == 1) {
            this.isPrevNext = true;
            this.collectionNotificationArr = response.response;
            if (this.collectionNotificationArr.length > 0) {
                this.notificationArr = this.collectionNotificationArr; // BECAUSE THE HTML ONLY LOOKS FOR NOTIFICATIONARR
                this.isPrevNext = false;
                this.showNotificationDetail();
            }
        }
    });*/
  }

  clickPrevNext(act) {
    var tmpNotificationArr = [];
    tmpNotificationArr = this.notificationArr;
    this.totalLength = tmpNotificationArr.length;
    if (act == 'next') {
      if (this.selectIndex < this.totalLength - 1) {
        this.notificationDetail = tmpNotificationArr[this.selectIndex + 1];
        this.selectIndex++;
      }
    } else if (act == 'prev') {
      if (this.selectIndex > 0) {
        this.notificationDetail = tmpNotificationArr[this.selectIndex - 1];
        this.selectIndex--;
      } else {
        $("#prevBtn").addClass('ísDisabled');
      }
    } else {
      alert('Something went wrong.');
    }
  }

  private openApp(appId, appType, urls, websiteUrl) {
    if (websiteUrl && websiteUrl != '') {
      window.open(websiteUrl, '_blank');
      return;
    }
    if (urls == '') {
      return;
    }
    if (appType == 7 || appType == 8 || appType == 9 || appType == 15 || appType == 16) {
      /******START Check Tenant login or not******/
      this.AppService.checkIsloggedin().subscribe(response => {
        if (response.status == 0) {
          window.open(APP_CONFIG.urls + '/' + window.location.pathname.split("/")[1], '_blank');
          return;
        }
        this.AppService.childOpenApp(appId, appType, urls);
      });
      /******END Check Tenant login or not******/
    } else {
      this.AppService.childOpenApp(appId, appType, urls);
    }
  }

  previousFeaturedApp() {
    if (this.fadeOutFeatured) {
      return;
    }
    this.fadeOutFeatured = true;
    setTimeout(() => {
      this.featuredAppIndex > 0 ?
        this.featuredAppIndex-- :
        this.featuredAppIndex = this.featuredApplicationArray.length - 1;

      this.fadeOutFeatured = false;
    }, 200);
  }

  nextFeaturedApp() {
    if (this.fadeOutFeatured) {
      return;
    }
    this.fadeOutFeatured = true;
    setTimeout(() => {
      this.featuredAppIndex < this.featuredApplicationArray.length - 1 ?
        this.featuredAppIndex++ :
        this.featuredAppIndex = 0;

      this.fadeOutFeatured = false;
    }, 200);
  }

  formatImageUrl(url) {
    var newUrl = url.split('.');
    newUrl[2] = newUrl[2] + '@2x';
    newUrl = newUrl.join('.').replace('default', 'hires');
    return newUrl;
  }

  hasPlatforms(platforms) {
    if (platforms.EnableAndroid == 1 || platforms.EnableApple == 1 || platforms.EnableIOS == 1 || platforms.EnableWeb == 1 || platforms.EnableWindows == 1) {
      return true;
    } else {
      return false;
    }
  }

  filterExpand() {
    if (!this.IsHidden) {
      this.renderer.removeClass(document.body, 'filters-shown');
    } else {
      this.renderer.addClass(document.body, 'filters-shown');
    }
  }

  showFilters() {
    this.renderer.addClass(document.body, 'filters-shown');
  }

  hideFilters() {
    this.renderer.removeClass(document.body, 'filters-shown');
  }

  searchFieldsNotEmpty() {
    return this.nameSearch > 0 || this.typeSearch > -1 || this.deviceSearch > -1 || this.schoolSearch.length > -1 || this.gradeSearch > -1 || this.statusSearch > -1 || this.subjectSearch > -1;
  }

  clearFilters() {
    this.typeSearch = [];
    this.deviceSearch = -1;
    this.schoolSearch = [];
    this.gradeSearch = [];
    this.statusSearch = -1;
    this.subjectSearch = [];
    this.typeSearchName = null;
    this.deviceSearchName = null;
    this.schoolSearchName = null;
    this.gradeSearchName = null;
    this.statusSearchName = null;
    this.subjectSearchName = null;
  }

  schoolSearchFunc(event) {
    this.schoolSearch = event.value.map(a => a.id);
  }

  pluckAttr(collection, attr) {
    if (collection && collection.length > 0) {
      return collection.map(a => a[attr]);
    }
  }

  typeSearchFunc(event) {
    this.typeSearch = event.value.map(a => a.id);
  }
  subjectSearchFunc(event) {
    this.subjectSearch = event.value.map(a => a.id);
  }
  pushGrade(idArr) {
    this.filterArray.grades.map((v1, k1) => {
      idArr.map((v2, k2) => {
        if (v1.id == v2 && this.gradeSearchName.map((gs) => gs.id).indexOf(v2) == -1) {
          this.gradeSearchName.push({ id: v2, name: v1.name });
        }
      });
    });
  }
  popGrade(idArr) {
    idArr.map((v1, k1) => {
      this.gradeSearchName.map((v2, k2) => {
        if (v1 == v2.id) {
          this.gradeSearchName.splice(k2, 1);
        }
      });
    });
  }
  gradeSearchFunc(event) {
    let groupObj = {
      16: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], //k-12
      18: [1, 2, 3, 4, 5, 6], //k-5
      17: [7, 8, 9, 10, 11, 12, 13], //6-12
      14: [1, 2, 3, 4] //k-3
    };

    let subGroupObj = {
      16: [14, 17, 18], //k-12
      18: [14] //k-5
    };
    if (!event.itemValue) {
      this.gradeSearch = this.gradeSearchName.map((gs) => gs.id);
      return;
    }
    var option = event.itemValue;
    var checked = false;
    event.value.map((r) => {
      if (r.id == option.id) {
        checked = true;
      }
    });

    if (option) {
      if (checked) {
        switch (option.id) {
          case 14:
          case 18:
          case 16:
          case 17:
            this.pushGrade(groupObj[option.id]);
            if (subGroupObj[option.id]) {
              this.pushGrade(subGroupObj[option.id]);
            }
            if (this.gradeSearchName.map((gs) => gs.id).indexOf(17) != -1 && this.gradeSearchName.map((gs) => gs.id).indexOf(18) != -1) {
              this.pushGrade([16]);
            }
            break;
          default:
            Object.keys(groupObj).map((v, k) => {
              if (groupObj[v].indexOf(option.id) != -1) {
                var flag = true;
                groupObj[v].map((r) => {
                  if (this.gradeSearchName.map((gs) => gs.id).indexOf(r) == -1) {
                    flag = false;
                  }
                });
                if (flag) {
                  this.pushGrade([parseInt(v)]);
                }
              }
            });
            break;
        }
      } else {
        switch (option.id) {
          case 14:
          case 18:
          case 16:
          case 17:
            this.popGrade(groupObj[option.id]);
            if (subGroupObj[option.id]) {
              this.popGrade(subGroupObj[option.id]);
            }
            Object.keys(subGroupObj).map((v, k) => {
              if (subGroupObj[v].indexOf(option.id) != -1) {
                var flag = true;
                subGroupObj[v].map((r) => {
                  if (this.gradeSearchName.map((gs) => gs.id).indexOf(r) == -1) {
                    flag = false;
                  }
                });
                if (!flag) {
                  this.popGrade([v]);
                }
              }
            });
            break;
          default:
            Object.keys(groupObj).map((v, k) => {
              if (groupObj[v].indexOf(option.id) != -1) {
                var flag = true;
                groupObj[v].map((r) => {
                  if (this.gradeSearchName.map((gs) => gs.id).indexOf(r) == -1) {
                    flag = false;
                  }
                });
                if (!flag) {
                  this.popGrade([v]);
                }
              }
            });
            break;
        }
      }
    }
    setTimeout(()=>{ this.gradeSearch = this.gradeSearchName.map((gs) => gs.id); },100)
  }

  searchBox(event){
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      this.nameSearch=event;
    },1000);
  }

  trackByApp(o,i){
    return i.LPApplicationId;
  }

  searchFunction(item){
    let status=this.searchFilter(item,this.nameSearch,this.typeSearch,this.deviceSearch,this.schoolSearch,this.gradeSearch,this.subjectSearch,this.statusSearch);    return status;
  }

  isArr(Arr: any) {
      if (Object.prototype.toString.call(Arr) == "[object Array]") {
          return true;
      }
      return false;
  }

  arrayContainsArray(superset, subset) {
      if (0 === subset.length) {
          return false;
      }
      return subset.every(function (value) {
          return (superset.indexOf(value) >= 0);
      });
  }

  searchFilter(item,nameSearch,typeSearch, deviceSearch, schoolSearch, gradeSearch, subjectSearch, statusSearch){
    if (!item.ApplicationName) {
      item.ApplicationName = "";
    }
    if ((nameSearch && nameSearch.length >0 ) && item.ApplicationName && item.ApplicationName.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1) {
        return false;
    }
    if (item.title) {
        if (item.apps && item.apps.length > 0) {
            var flag = false;
            if (!nameSearch && typeSearch.length == 0 && schoolSearch.length == 0 && gradeSearch.length == 0 && subjectSearch.length == 0 && statusSearch == -1 && deviceSearch == -1) {
                return true
            }
            item.type = [];
            item.subject = [];
            item.grades = [];
            item.school = [];
            item.status = [];
            item.platform = [];
            item.AppNames = [];
            item.AppNames.push(item.title);
            item.apps.map((row) => {
                if (row.hasOwnProperty('ApplicationName')) {
                    item.AppNames.push(row['ApplicationName']);

                    if (this.isArr(row['type'])) {
                        row['type'].map((i) => item.type.push(i));
                    }
                    if (this.isArr(row['subject'])) {
                        row['subject'].map((i) => item.subject.push(i));
                    }
                    if (this.isArr(row['grades'])) {
                        row['grades'].map((i) => item.grades.push(i));
                    }
                    if (this.isArr(row['school'])) {
                        row['school'].map((i) => item.school.push(i));
                    }

                    if (row['status']) {
                        item.status.push(row['status']);
                    }
                    if (row['platform']) {
                        try { row.platform = JSON.parse(row.platform); } catch (e) { }
                        if (Object.prototype.toString.call(row.platform) == "[object Object]") {
                            if (parseInt(row.platform.EnableAndroid) == 1 && !item.platform.includes('EnableAndroid')) {
                                item.platform.push("EnableAndroid");
                            }
                            if (parseInt(row.platform.EnableIOS) == 1 && !item.platform.includes('EnableIOS')) {
                                item.platform.push("EnableIOS");
                            }
                            if (parseInt(row.platform.EnableWeb) == 1 && !item.platform.includes('EnableWeb')) {
                                item.platform.push("EnableWeb");
                            }
                            if (parseInt(row.platform.EnableApple) == 1 && !item.platform.includes('EnableApple')) {
                                item.platform.push("EnableApple");
                            }
                            if (parseInt(row.platform.EnableWindows) == 1 && !item.platform.includes('EnableWindows')) {
                                item.platform.push("EnableWindows");
                            }
                        }
                    }
                }
            });
        }
        if (nameSearch && item.ApplicationName && !item.AppNames.join().includes(nameSearch)) {
            return false;
        }

        if (deviceSearch && deviceSearch != -1) {
            if (deviceSearch == 1 && item.platform && !item.platform.includes('EnableAndroid')) {
                return false;
            } else if (deviceSearch == 2 && !item.platform.includes('EnableIOS')) {
                return false;
            } else if (deviceSearch == 3 && !item.platform.includes('EnableWeb')) {
                return false;
            } else if (deviceSearch == 4 && !item.platform.includes('EnableApple')) {
                return false;
            } else if (deviceSearch == 5 && !item.platform.includes('EnableWindows')) {
                return false;
            }
        }
        if (this.isArr(item.school) && this.isArr(schoolSearch)) {
            if (schoolSearch.length > 0 && !this.arrayContainsArray(item.school,schoolSearch)) {
                return false;
            }
        }
        if (this.isArr(item.type) && this.isArr(typeSearch)) {
            if (typeSearch.length > 0 && !this.arrayContainsArray(item.type,typeSearch)) {
                return false;
            }
        }

        if (this.isArr(item.subject) && this.isArr(subjectSearch)) {
            if (subjectSearch.length > 0 && !this.arrayContainsArray(item.subject,subjectSearch)) {
                return false;
            }
        }

        if (this.isArr(item.grades) && this.isArr(gradeSearch)) {
            if (gradeSearch.length > 0 && !this.arrayContainsArray(item.grades,gradeSearch)) {
                return false;
            }
        }
        if (statusSearch && item.status && !item.status.includes(statusSearch) && statusSearch != -1) {
            return false;
        }
        return true;
    } else {
        if (deviceSearch && deviceSearch != -1 && item.platform) {
            if (deviceSearch == 1 && parseInt(item.platform.EnableAndroid) == 0) {
                return false;
            } else if (deviceSearch == 2 && parseInt(item.platform.EnableIOS) == 0) {
                return false;
            } else if (deviceSearch == 3 && parseInt(item.platform.EnableWeb) == 0) {
                return false;
            } else if (deviceSearch == 4 && parseInt(item.platform.EnableApple) == 0) {
                return false;
            } else if (deviceSearch == 5 && parseInt(item.platform.EnableWindows) == 0) {
                return false;
            }
        }

        if (this.isArr(item.school) && this.isArr(schoolSearch)) {
            if (schoolSearch.length > 0 && !this.arrayContainsArray(item.school,schoolSearch)) {
                return false;
            }
        }
        if (this.isArr(item.type) && this.isArr(typeSearch)) {
            if (typeSearch.length > 0 && !this.arrayContainsArray(item.type,typeSearch)) {
                return false;
            }
        }

        if (this.isArr(item.subject) && this.isArr(subjectSearch)) {
            if (subjectSearch.length > 0 && !this.arrayContainsArray(item.subject,subjectSearch)) {
                return false;
            }
        }

        if (this.isArr(item.grades) && this.isArr(gradeSearch)) {
            if (gradeSearch.length > 0 && !this.arrayContainsArray(item.grades,gradeSearch)) {
                return false;
            }
        }

        if (statusSearch && item.status != statusSearch && statusSearch != -1) {
            return false;
        }
        return true;
    }
  }


}
