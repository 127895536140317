interface AppConfig {
    nodeapiUrl: string,
    userbucket:string,    
    launchpadcdn:string,
    launchpadwebcdn:string,
    urls: string
}

export const APP_CONFIG: AppConfig = {
  	'nodeapiUrl': "https://nodeapi.classlink.com/digitalresources",
  	'userbucket': '//d3qm7sp1csrxvx.cloudfront.net',
  	'launchpadcdn': "//dp3vjvsy1r9i0.cloudfront.net",
  	'launchpadwebcdn': "//d37bd8ndmtxw3o.cloudfront.net",
    'urls': 'https://launchpad.classlink.com'
};