import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-missing',
  templateUrl: './missing.component.html',
  styleUrls: ['./missing.component.css']
})
export class MissingComponent implements OnInit {

  constructor() {

    }

  ngOnInit() {

  }

}
