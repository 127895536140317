import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppService} from "./applications/shared/app.service";
import {APP_CONFIG} from './app-config';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements  OnInit {
    public isCodeValid: boolean = false;
    isCodeMissing: boolean = false;
    isResponse: boolean = false;
    isLoader: boolean = true;
    districtDetails = '';
    schCode='';

    // DUMMY DATA: REMOVE
/*    public isCodeValid: boolean = true; 
    isCodeMissing: boolean = false;
    isResponse: boolean = true;
    isLoader: boolean = false;
    districtDetails = {title: 'Digital Resources'};*/
    // END DUMMY

    launchpadcdn = APP_CONFIG.launchpadcdn;  
    constructor(private router: Router, private AppService: AppService) {} 
    ngOnInit() {
      this.getDistrict();
    }
    
    private getDistrict() {
      var fullRoute = window.location.pathname;
      var code = fullRoute.split("/");
      if (fullRoute == '/') {
          this.isLoader = false;
          this.isCodeMissing = true;
          return;
      } else {
          this.schCode=code[1];
          this.AppService.getSettingByCode('/' + code[1]).subscribe(response => {
              this.isResponse = true;
              if (response.status == 1) {
                  this.isLoader = false;
                  this.isCodeValid = true;
                  this.districtDetails = response.response;
              } else {
                  this.isLoader = false;
                  this.isCodeValid = false;
              }
          });
      }
    }

    isSubPage() {
        return window.location.href.includes('app') || window.location.href.includes('collection');
    }
}