import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from './../app-config';
import { AppService } from "./../applications/shared/app.service";
import * as $ from 'jquery';
import { Observable, from, timer } from 'rxjs';
import { concatMap, map, scan} from 'rxjs/operators';

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html'
})
export class CollectionComponent implements OnInit {
    appCollectionsArray: any = [];
    collectionId = '';
    launchpadcdn = APP_CONFIG.launchpadcdn;
    notificationArr = [];
    notificationList = [
        { title: 'Notification Title', value: 'Notification message'}
    ];
    notificationDetail: any = "";
    nLoader=false;
    selectIndex: number;
    disableClass:string;
    totalLength:number;
    isPrevNext = false;
    notificationCollectionListArr = "";
    notificationLength = [];
    featuredAppsLength:any = [];
    displayNotifications = true;
    applicationOfCollectionArray:Observable<Array<any>>;
    featuredApplicationArray:Observable<Array<any>>;
    
    constructor(private router: Router, 
                private AppService: AppService, 
                private route: ActivatedRoute, private elementRef: ElementRef) { }

    ngOnInit() {
        this.getAppCollectionsDetails();
    }

    ngAfterViewInit() {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = "assets/plugins/bootstrap-select/js/select-filter.js";
        this.elementRef.nativeElement.appendChild(s);
    }

    private getAppCollectionsDetails() {
        const timeOf = (interval: number) => <T>(val: T) =>
          timer(interval).pipe(map(x => val));
        let collectionId = this.route.snapshot.params["id"];
        var fullRoute = window.location.pathname;
        var code = fullRoute.split("/");
        this.AppService.getCollectionsDetails(code[1], collectionId).subscribe(response => {
            if (response.status == 1) {;
                this.applicationOfCollectionArray = from(response.response.apps)
                .pipe(
                  concatMap(timeOf(10)),
                  map(x => [x]),
                  scan((acc, val) => [...acc, ...val]),
                );
                this.featuredApplicationArray = from(response.response.featuredApps)
                .pipe(
                  concatMap(timeOf(10)),
                  map(x => [x]),
                  scan((acc, val) => [...acc, ...val]),
                );
                this.appCollectionsArray = response.response;
                this.notificationLength = response.response.notificationList && response.response.notificationList.length > 0 ? response.response.notificationList.length : '';
                this.featuredAppsLength = response.response.featuredApps && response.response.featuredApps.length > 0 ? response.response.featuredApps.length : '';
            } else {
                this.router.navigateByUrl('/404');
            }
        });
    }

    public myAppDetail(id, customurlcode) {
        this.router.navigate(['/' + customurlcode + '/app', id]);
    }

    public showNotification(notificationObj) {
        this.nLoader=false;
        this.isPrevNext = false;
        this.notificationArr = [];
        this.notificationArr = notificationObj;
        this.showNotificationDetail();
        /*var fullRoute = this.router.url;
        var code = fullRoute.split("/");
        this.AppService.getNotificationDetails('/' + code[1], app_id).subscribe(response => {
            this.nLoader=true;
            if (response.status == 1) {
                this.isPrevNext = true;
                this.notificationArr = response.response;
                if(this.notificationArr.length > 0){
                    this.isPrevNext = false;
                    this.showNotificationDetail()
                }
            }
        });*/
    }

    public showNotificationDetail() {
       /* this.selectIndex = index;
        this.notificationDetail = rowObj;*/
        $('#nDetail').click();
    }

    public showCollectionNotificationList(rowObj) {
        this.notificationCollectionListArr = rowObj;
        $('#nCollectionDetail').click();
    }    
    
    clickPrevNext(act) {
        var tmpNotificationArr = this.notificationArr;
        this.totalLength = tmpNotificationArr.length;
        if (act == 'next') {
            if (this.selectIndex < this.totalLength-1) {
                this.notificationDetail = tmpNotificationArr[this.selectIndex + 1];
                this.selectIndex++;
            }
        } else if (act == 'prev') {
            if (this.selectIndex > 0) {
                this.notificationDetail = tmpNotificationArr[this.selectIndex - 1];
                this.selectIndex--;
            } else {
                $("#prevBtn").addClass('ísDisabled');
            }
        } else {
            alert('Something went wrong.');
        }
    }

    private openApp(appId, appType, urls, websiteUrl) {
        if(websiteUrl && websiteUrl!=''){
            window.open(websiteUrl, '_blank');
            return;
        }
        if(appType == 7 || appType == 8 || appType == 9 || appType == 15 || appType == 16){
            /******START Check Tenant login or not******/
            this.AppService.checkIsloggedin().subscribe(response => {
                if (response.status == 0) {
                     window.open(APP_CONFIG.urls + '/' + window.location.pathname.split("/")[1], '_blank');
                     return;
                }            
                this.AppService.childOpenApp(appId, appType, urls);
            });
            /******END Check Tenant login or not******/
        } else {
            this.AppService.childOpenApp(appId, appType, urls);
        }
    }

    formatImageUrl(url) {
        var newUrl = url.split('.');
        newUrl[2] = newUrl[2] + '@2x';
        newUrl = newUrl.join('.').replace('default', 'hires');
        return newUrl;
    }

}
