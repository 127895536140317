import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, PipeTransform, Pipe } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { appRoutes } from './app-routing.module';
import { PreloadAllModules, RouterModule } from "@angular/router";

//Aplications
import { AppComponent } from './app.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationListComponent } from './applications/application-list/application-list.component';
import { ApplicationDetailsComponent } from './applications/application-details/application-details.component';

//Collection
import { CollectionComponent } from './collection/collection.component';
import { MissingComponent } from './missing/missing.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AppService } from "./applications/shared/app.service";
import { SearchPipePipe } from './applications/shared/search-pipe.pipe';

import { PopoverModule } from "ng2-popover";
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@NgModule({
  declarations: [
    AppComponent,
    ApplicationsComponent,
    CollectionComponent,
    ApplicationListComponent,
    ApplicationDetailsComponent,
    MissingComponent,
    NotfoundComponent,
    SearchPipePipe,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    PopoverModule,
    DropdownModule,
    MultiSelectModule,
    RouterModule.forRoot(appRoutes),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    })
  ],
  providers: [
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {




}
