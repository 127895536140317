import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

var inputLang = 'en';
declare const require;
var allowedLang = ['en', 'fr', 'ar','da','dk','en-gb','es','it','pt','sv','zh'];
var translations='';
if (allowedLang.indexOf(inputLang) == -1) {
     translations = require(`raw-loader!./locale/messages.en.xlf`);
} else {
     translations = require(`raw-loader!./locale/messages.${inputLang}.xlf`);
}
platformBrowserDynamic().bootstrapModule(AppModule, {
        providers: [
            {provide: TRANSLATIONS, useValue: translations},
            {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
        ]
});

